import React, {useState, Dispatch, SetStateAction} from "react";
import Modal from 'react-modal';
import { UseFormRegister, FieldErrors } from "react-hook-form";
import moment from 'moment';
import 'moment/locale/ja';

interface Props {
  register: UseFormRegister<any>;
  start_times: { [key: number]: string; };
  play_styles: { [key: number]: string; };
  exclusion_conditions: { [key: number]: string; };
  play_style_in_price_modal: string;
  setOpenedDetailedInformationModal: Dispatch<SetStateAction<boolean>>;
}

moment.locale('ja');

const modalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    backgroundColor: '#444444',
    zIndex: '9999',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    transform: 'translate(-50%, -50%)',
    borderRadius: '0',
    padding: '0',
    height: '450px',
    overflow: 'hidden',
  },
}

const DetailedInformationEditor: React.FC<Props> = (
  {
    register,
    start_times,
    play_styles,
    exclusion_conditions,
    play_style_in_price_modal,
    setOpenedDetailedInformationModal,
  }
  ) => {
  const [modalIsOpen, setIsOpen] = useState(false);

  return (
    <>
      <button type="button" className="more-button" onClick={() => setIsOpen(true)} >さらに条件を追加</button>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={() => setOpenedDetailedInformationModal(true)}
        onRequestClose={() => setIsOpen(false)}
        contentLabel="DetailedInfromation Editor"
        style={modalStyles}
      >
        <div className="form-wrapper">
          <div className="form detailed-information">
            <p className="title">スタート時間&nbsp;&nbsp;<span className="note">※複数選択可</span></p>
            <div className="field start-time">
              {Object.keys(start_times).map(id => (
                <span key={id}>
                  <input type="checkbox" value={id} id={`start_time-${id}`} {...register('startTimes')} />
                  <label htmlFor={`start_time-${id}`}>
                    {start_times[id]}
                  </label>
                </span>
              ))}
            </div>
            <p className="title">プレイスタイル</p>
            <div className="field play-style">
              {Object.keys(play_styles).filter(id => !play_style_in_price_modal.split(',').includes(id)).map(id => (
                <div key={id}>
                  <input type="checkbox" value={id} id={`play_style-${id}`} {...register('playStyles')} />
                  <label htmlFor={`play_style-${id}`}>
                    {play_styles[id]}
                  </label>
                </div>
              ))}
            </div>
            <p className="title">除外指定</p>
            <div className="field exclusion_condition">
              {Object.keys(exclusion_conditions).map(id => (
                <span key={id}>
                  <input type="checkbox" value={id} id={`exclusion_condition-${id}`} {...register('exclusionConditions')} />
                  <label htmlFor={`exclusion_condition-${id}`}>
                    {exclusion_conditions[id]}
                  </label>
                </span>
              ))}
            </div>
          </div>
          <button type="button" className="close-button" onClick={() => setIsOpen(false)}>決定する</button>
        </div>
      </Modal>
    </>
  );
}

export default DetailedInformationEditor
