import React, { useState } from "react";
import Modal from 'react-modal';
import { UseFormRegister, UseFormWatch } from "react-hook-form";

interface Props {
  register: UseFormRegister<any>;
  areas: { [key: number]: string; };
  watch: UseFormWatch<any>;
}

Modal.setAppElement('body');

const modalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    backgroundColor: '#444444',
    zIndex: '9999',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    transform: 'translate(-50%, -50%)',
    borderRadius: '0',
    padding: '0',
    height: '342.5px',
  },
};

const AreaSelector: React.FC<Props> = (
  {
    register,
    areas,
    watch,
  }
) => {
  const [modalIsOpen, setIsOpen] = useState(false);

  const afterOpenModal = () => (event: any) => {
    return false;
  };
  const selectedAreaNames = (watchSelectedAreaIDs) => {
    let selectedAreaNames = 'エリアを選択';
    if(watchSelectedAreaIDs && watchSelectedAreaIDs.length != 0) {
      const selectedAreaNamesFullText = watchSelectedAreaIDs.map(id => areas[id]).join();
      selectedAreaNames = selectedAreaNamesFullText.length <= 23 ? selectedAreaNamesFullText : `${selectedAreaNamesFullText.substr(0, 23)}...`;
    }

    return selectedAreaNames;
  }

  return (
    <div className="selector-wrapper">
      <div className="box area" onClick={() => setIsOpen(true)}>
        {selectedAreaNames(watch('areas'))}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={() => setIsOpen(false)}
        contentLabel="Area Selector"
        style={modalStyles}
      >
        <div className="form-wrapper area">
          <div className="form area">
            <p className="title">エリア</p>
            <div className="field area">
              {Object.keys(areas).map(id => (
                <span key={id}>
                  <input type="checkbox" value={id} id={`selected_area-${id}`} {...register('areas') } />
                  <label htmlFor={`selected_area-${id}`}>
                    {areas[id]}
                  </label>
                </span>
              ))}
            </div>
          </div>
          <button type="button" className="close-button" onClick={() => setIsOpen(false)}>決定する</button>
        </div>
      </Modal>
    </div>
  );
}

export default AreaSelector
