import React, { useState } from "react";
import Modal from 'react-modal';
import Slider from "@mui/material/Slider";
import { UseFormRegister } from "react-hook-form";

interface Props {
  register: UseFormRegister<any>;
  price: number[];
  defaultMinimumPrice: number;
  defaultMaxPrice: number;
  setPrice: any;
  play_styles: { [key: number]: string; };
  play_style_in_price_modal;
}

Modal.setAppElement('body');

const modalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    backgroundColor: '#444444',
    zIndex: '9999',
  },

  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    transform: 'translate(-50%, -50%)',
    borderRadius: '0',
    padding: '0',
    height: '370px',
  },
};

const PriceSelector: React.FC<Props> = (
  {
    register,
    price,
    defaultMinimumPrice,
    defaultMaxPrice,
    setPrice,
    play_styles,
    play_style_in_price_modal,
  }
) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectedPriceMoreThanOnce, setSelectedPriceMoreThanOnce] = useState(false);
  const afterOpenModal = () => (event: any) => {
    return false;
  };
  const selectedPrice = () => {
    if(selectedPriceMoreThanOnce || price[0] != defaultMinimumPrice || price[1] != defaultMaxPrice) {
      return `${price[0]}円〜${price[1]}円`;
    }
    return 'プレー料金を入力';
  }

  return (
    <div className="selector-wrapper">
      <div className="box price" onClick={() => setIsOpen(true)}>
        {selectedPrice()}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={() => setIsOpen(false)}
        contentLabel="Price Selector"
        style={modalStyles}
      >
        <div className="form-wrapper">
          <div className="form price">
            <p className="title">プレー料金</p>
            <div className="field price">
              <div className="slider">
                <div className="text-box-wrapper">
                  <input type="number" name="minimum_price" value={price[0]} disabled />
                  &nbsp;〜&nbsp;
                  <input type="number" name="max_price" value={price[1]} disabled />
                  <span className="yen">円</span>
                </div>
                <Slider
                  getAriaLabel={() => 'Temperature range'}
                  value={price}
                  onChange={(e: Event, newValue: number | number[]) => {
                    setPrice(newValue as number[]);
                    setSelectedPriceMoreThanOnce(true);
                  }}
                  valueLabelDisplay="auto"
                  step={2500}
                  min={defaultMinimumPrice}
                  max={defaultMaxPrice}
                  size="small"
                  sx={{
                    width: 250,
                    color: 'success.main',
                  }}
                />
              </div>
            </div>
            <div className="field play-style">
              {Object.keys(play_styles).filter(id => play_style_in_price_modal.split(',').includes(id)).map(id => (
                <div key={id}>
                  <input type="checkbox" value={id} id={`play_style-${id}`} {...register('playStylesInPriceModal')} />
                  <label htmlFor={`play_style-${id}`}>
                    {play_styles[id]}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <button type="button" className="close-button" onClick={() => setIsOpen(false)}>決定する</button>
        </div>
      </Modal>
    </div>
  );
}

export default PriceSelector
