import React, { Dispatch } from "react";
import {
  useFieldArray,
  FieldErrors,
  UseFormRegister,
  UseFormWatch,
} from "react-hook-form";
import { Control } from 'react-hook-form';
import 'react-popper-tooltip/dist/styles.css';
import UserSelector from "./UserSelector";
import Modal from 'react-modal';

const modalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(68, 68, 68, 0.75)', // semi-transparent background
    zIndex: '9999',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    borderRadius: '4px',
    border: 'none', // remove default border
    padding: '0',
    width: '80%', // set a max width
    maxWidth: '400px', // set a max width
    overflow: 'hidden',
  },
};

interface Props {
  register: UseFormRegister<any>;
  control: Control<any>;
  watch: UseFormWatch<any>;
  errors: FieldErrors;
  stations: [];
  car_statuses: { [key: number]: string; };
  default_user_car_status: string;
  cookies_users: [];
  setOpenedUserErrorModal: Dispatch<SetStateAction<boolean>>;
  openedUserErrorModal: boolean;
}

const UsersEditor: React.FC<Props> = (
  {
    register,
    control,
    watch,
    errors,
    stations,
    car_statuses,
    default_user_car_status,
    cookies_users,
    setOpenedUserErrorModal,
    openedUserErrorModal,
  }
) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'users',
  });

  return (
    <>
      <ul>
        {fields.map((item, index) => (
          <li key={item.id}>
            <div className="form">
              <div className="label">
                <p>参加者：{index+1}&nbsp;<span className="max-count">(最大4人まで)</span></p>
                {watch('users').length > 1 &&
                    <button type="button" className="delete-button" onClick={() => remove(index)}>削除</button>
                }
              </div>
              <UserSelector register={register} control={control} watch={watch} errors={errors}
                            car_statuses={car_statuses} index={index} stations={stations}
                            cookies_users={cookies_users} />
            </div>
          </li>
        ))}
      </ul>
      <button type="button" className={`user-addition more-button ${watch('users').length >= 4 ? "disabled" : ""}`} onClick={() => append({carStatus: default_user_car_status})}>参加者を追加</button>
      <Modal
        isOpen={openedUserErrorModal}
        onRequestClose={() => setOpenedUserErrorModal(false)}
        contentLabel="Validation Modal"
        style={modalStyles}
   j   >
        <div className="alert-wrapper">
          <h1>最寄り駅を入力してください</h1>
          <p>最寄り駅を入力して車での出発時間と乗り合わせルートを確認しよう</p>
          <img src="images/sample-route.jpg" />
          <button type="button" className="close-button" onClick={() => setOpenedUserErrorModal(false)}>入力する</button>
        </div>
      </Modal>
    </>
  );
}


export default UsersEditor
