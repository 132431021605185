import React from 'react'

const Footer: React.FC = () => {
  return (
    <>
      <div className="menu">
        <a href="/terms">利用規約</a>
        <a href="/policy">プライバシーポリシー</a>
      </div>
      <img src="/images/white-title.svg" />
      <p>©︎ゴルギメ ALL RIGHTS RESERVED.</p>
    </>
  )
}

export default Footer
