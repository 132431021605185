import React, { useState } from "react";
import {UseFormRegister, FieldErrors, UseFormWatch, Controller, Control} from "react-hook-form";
import 'moment/locale/ja';
import { usePopperTooltip } from "react-popper-tooltip";
import Select from 'react-select';

interface Props {
  register: UseFormRegister<any>;
  control: Control<any>;
  watch: UseFormWatch<any>;
  errors: FieldErrors;
  car_statuses: { [key: number]: string; };
  index: number;
  stations: [];
  cookies_users: [];
}

const UserSelector: React.FC<Props> = (
  {
    register,
    control,
    watch,
    errors,
    car_statuses,
    index,
    stations,
    cookies_users,
  }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [nearestStationList,setNearestStationList] = useState(stations);
  const [clickedCarStatusBox, setClickedCarStatusBox] = useState(false);

  const {
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
    getArrowProps,
  } = usePopperTooltip({
    trigger: 'click',
    placement: 'bottom',
    offset: [15, 10],
    interactive: true,
    closeOnOutsideClick: true,
    visible: isVisible,
    onVisibleChange: setIsVisible,
  });

  const customStyles = {
    control: (provided, _) => ({
      ...provided,
      backgroundColor: '#DADADA',
      border: 'none',
      paddingTop: 7,
      paddingRight: 4,
      paddingBottom: 7,
      paddigLeft: 4,
    }),
  }

  const selectedCarStatus = (watchCarStatus) => {
    if (watchCarStatus && clickedCarStatusBox ||
      cookies_users && cookies_users[index] && cookies_users[index]['carStatus']) {
      return car_statuses[watchCarStatus];
    }
    return '交通手段';
  }

  const onInputChange = (inputValue: string): void => {
    if(inputValue != '') {
      setNearestStationList(stations);
    } else {
      setNearestStationList([]);
    }
  }

  const onFocus = () => {
    setNearestStationList([]);
  }

  const carStatusBoxClick = () => {
    if (!clickedCarStatusBox) {
      setClickedCarStatusBox(true);
    }
  }

  return (
    <>
      <div className="box-wrapper">
        <Controller
          control={control}
          name={`users.${index}.nearestStation`}
          rules={{ required: true }}
          render={ ({field}) => <Select
            {...field}
            options={nearestStationList}
            placeholder="最寄り駅"
            styles={customStyles}
            isVisible
            className="box station"
            onFocus={onFocus}
            onInputChange={onInputChange}
            noOptionsMessage={() => '最寄り駅を入力'}
            components={{
              IndicatorSeparator: () => null,
            }}
          />}
        />
        <div className="box car-status" ref={setTriggerRef} onClick={carStatusBoxClick} >
          {selectedCarStatus(watch(`users.${index}.carStatus`))}
        </div>
      </div>
    {visible && (
      <div
        ref={setTooltipRef}
        {...getTooltipProps({ className: 'tooltip-container' })}
      >
        <div {...getArrowProps({ className: 'tooltip-arrow' })} />
        <div className="form-wrapper">
          <div className="form car-status">
            <p className="title">交通手段</p>
            <div className="field car-status">
              {Object.keys(car_statuses).map(key => (
                <span key={key}>
                  <input
                    type="radio"
                    name="carStatus"
                    id={`users.${index}.${key}`}
                    value={key}
                    {...register(`users.${index}.carStatus`, { required: true })}
                  />
                  <label htmlFor={`users.${index}.${key}`}>
                    {car_statuses[key]}
                  </label>
                </span>
              ))}
            </div>
          </div>
          <button type="button" className="close-button" onClick={() => setIsVisible(false)}>閉じる</button>
        </div>
      </div>
    )}
    </>
  );
}

export default UserSelector
