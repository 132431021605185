import React, { useEffect, useState } from "react";
import axios from 'axios';
import { useForm, SubmitHandler } from "react-hook-form";
import moment from 'moment';
import { useCookies } from "react-cookie";
import 'moment/locale/ja';
import DetailedInformationEditor from "./commons/DetailedInformationEditor";
import SelectedDetailedInformation from "./commons/SelectedDetailedInformation";
import AreaSelector from "./commons/AreaSelector";
import PriceSelector from "./commons/PriceSelector";
import DateInput from "./commons/DateInput";
import UsersEditor from "./commons/UsersEditor";
import Header from "./Header";

interface Props {
  authenticity_token: string;
  car_statuses: { [key: number]: string; };
  areas: { [key: number]: string; };
  start_times: { [key: number]: string; };
  play_styles: { [key: number]: string; };
  exclusion_conditions: { [key: number]: string; };
  stations: [];
  play_style_in_price_modal: string;
  default_exclusion_conditions: string;
  default_user_car_status: string;
}

interface FormInputs {
  areas: [number];
  startTimes: [number];
  playStyles: [number];
  playStylesInPriceModal: [number];
  exclusionConditions: [number];
  date: string;
  users: [
    {
      nearestStation: { [key: string]: number; },
      carStatus: number,
    }];
}

moment.locale('ja');

const EventEditor: React.FC<Props> = (
  {
    authenticity_token,
    car_statuses,
    areas,
    start_times,
    play_styles,
    exclusion_conditions,
    stations,
    play_style_in_price_modal,
    default_exclusion_conditions,
    default_user_car_status,
  }
) => {
  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FormInputs>({
    defaultValues: {
      playStyles: [],
      playStylesInPriceModal: [],
      exclusionConditions: default_exclusion_conditions.split(','),
      users: [{carStatus: default_user_car_status}],
    },
    shouldFocusError: false,
  });

  let defaultMinimumPrice = 0;
  let defaultMaxPrice = 25000;
  const nowDatetime = new Date();
  const nextWeekDatetime = new Date(nowDatetime.setDate(nowDatetime.getDate() + 7));
  const [price, setPrice] = useState<number[]>([defaultMinimumPrice, defaultMaxPrice]);
  const [isNotExistDriver, setIsNotExistDriver] = useState(false);
  const [isNeedDriverWithPassenger, setIsNeedDriverWithPassenger] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies();
  const [openedDetailedInformationModal, setOpenedDetailedInformationModal] = useState(false);
  const [openedUserErrorModal, setOpenedUserErrorModal] = useState(false);

  useEffect(() => {
    if(cookies.areas) {
      setValue('areas', cookies.areas);
    }
    if(cookies.minimumPrice || cookies.maxPrice) {
      const min = cookies.minimumPrice || defaultMinimumPrice;
      const max = cookies.maxPrice || defaultMaxPrice;
      setPrice(_ => [min, max]);
    }
    if(cookies.startTimes) {
      setValue('startTimes', cookies.startTimes);
    }
    if(cookies.playStyles) {
      setValue('playStyles', cookies.playStyles);
    }
    if(cookies.playStylesInPriceModal) {
      setValue('playStylesInPriceModal', cookies.playStylesInPriceModal);
    }
    if(cookies.exclusionConditions) {
      setValue('exclusionConditions', cookies.exclusionConditions);
    }
    if(cookies.date) {
      const date = new Date(cookies.date)
      setValue('date', date);
    }
    if(cookies.users) {
      setValue('users', cookies.users);
    }
  }, []);

  const onSubmit: SubmitHandler<FormInputs> = (data) => {
    if (!data['users'].some(user => user.carStatus == 1 || user.carStatus == 2)) {
      setIsNotExistDriver(true);
      return;
    }
    if(data['users'].some(user => user.carStatus == 3) && !data['users'].some(user => user.carStatus == 1)) {
      setIsNotExistDriver(false);
      setIsNeedDriverWithPassenger(true);
      return;
    }
    axios.defaults.headers.common = {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN' : authenticity_token
    }
    const users = data['users'].map((user, i) => ({
      nickname: `参加者${i+1}`,
      station_id: user.nearestStation.value,
      car_status_id: user.carStatus,
    }));
    var date = moment(data.date).format("YYYY-MM-DD");
    if(!data.date) {
      date = moment(moment().add(1,'days').toDate()).format("YYYY-MM-DD");
    }
    const event = {
      event: {
        users_attributes: users,
        area_ids: data.areas,
        minimum_price: price[0],
        max_price: price[1],
        start_time_ids: data.startTimes,
        play_style_ids: data.playStyles.concat(data.playStylesInPriceModal),
        exclusion_condition_ids: data.exclusionConditions,
        date: date,
      },
    }
    axios.post('/api/v1/events', event).then(res => {
      if (res.data.status == 'SUCCESS') {
        if(data.areas) {
          setCookie('areas', data.areas, { expires: nextWeekDatetime, path: '/' });
        }
        if(price[0] != defaultMinimumPrice) {
          setCookie('minimumPrice', price[0], { expires: nextWeekDatetime, path: '/' });
        }
        if(price[1] != defaultMaxPrice) {
          setCookie('maxPrice', price[1], { expires: nextWeekDatetime, path: '/' });
        }
        if(data.startTimes) {
          setCookie('startTimes', data.startTimes, { expires: nextWeekDatetime, path: '/' });
        }
        if(data.playStyles) {
          setCookie('playStyles', data.playStyles, { expires: nextWeekDatetime, path: '/' });
        }
        if(data.playStylesInPriceModal) {
          setCookie('playStylesInPriceModal', data.playStylesInPriceModal, { expires: nextWeekDatetime, path: '/' });
        }
        if(data.exclusionConditions) {
          setCookie('exclusionConditions', data.exclusionConditions, { expires: nextWeekDatetime, path: '/' });
        }
        if(data.date) {
          setCookie('date', moment(data.date).format("YYYY-MM-DD"), { expires: nextWeekDatetime, path: '/' });
        }
        if(data['users']) {
          const users = data['users'].map((user, i) => ({
            nearestStation: user.nearestStation,
            carStatus: user.carStatus,
          }));
          setCookie(`users`, users, { expires: nextWeekDatetime, path: '/' });
        }
        gtag('event', 'click', {
          'event_category': 'ゴルフ場検索ボタン',
          'event_label': `e=${res.data.data.random_string}`,
          'value': 1
        });
        /*
        gtag('event', 'conversion', {
          'send_to': 'AW-10973159917/TmjLCLHOzuADEO3DtPAo'
        });
         */
        location.href = `/golf_courses?e=${res.data.data.random_string}`;
      }
    })
  };

  const onError = (errors, _) => {
    if(errors.users) {
      setOpenedUserErrorModal(true);
    }
  };

  return (
    <div id="container" className="top">
      <header>
        <Header />
      </header>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="basic-info">
          <div className="sub-title">
            <h2>みんなの住んでる場所から探せる<br />ゴルフ場検索はゴルギメ！<span className="notice">※特許申請中</span></h2>
          <p>出発時間も乗り合わせルートもスグに分かる！</p>
          </div>
          <AreaSelector areas={areas} register={register} watch={watch}/>
          <DateInput control={control} errors={errors} watch={watch} />
          <PriceSelector price={price} register={register} defaultMinimumPrice={defaultMinimumPrice}
                         defaultMaxPrice={defaultMaxPrice} setPrice={setPrice} play_styles={play_styles}
                         play_style_in_price_modal={play_style_in_price_modal} />
          <div className="more-button-wrapper">
            <DetailedInformationEditor register={register} start_times={start_times} play_styles={play_styles}
                                       exclusion_conditions={exclusion_conditions}
                                       play_style_in_price_modal={play_style_in_price_modal}
                                       setOpenedDetailedInformationModal={setOpenedDetailedInformationModal} />
          </div>
          <SelectedDetailedInformation watch={watch} start_times={start_times} play_styles={play_styles}
                                       exclusion_conditions={exclusion_conditions}
                                       cookies_exclusion_conditions={cookies.exclusionConditions}
                                       openedDetailedInformationModal={openedDetailedInformationModal} />
          <div className="users-wrapper">
            <UsersEditor register={register} control={control} watch={watch} errors={errors} stations={stations}
                         car_statuses={car_statuses} default_user_car_status={default_user_car_status}
                         cookies_users={cookies.users} setOpenedUserErrorModal={setOpenedUserErrorModal}
                         openedUserErrorModal={openedUserErrorModal} />
          </div>
        </div>
        <div className="save-button-wrapper">
          <input type="submit" className="apricot-button" value="検索" />
        </div>
        { isNotExistDriver && <p className="error">交通手段で車(同乗者OK)か車(同乗者NG)を一台以上えらんでください</p> }
        { isNeedDriverWithPassenger && <p className="error">交通手段で車(同乗)をえらぶ場合、車(同乗者OK)を一台以上えらんでください</p> }
      </form>
    </div>
  );
}


export default EventEditor
