import React, {useEffect, useState} from 'react'
import moment from "moment";
import { useForm } from "react-hook-form";

interface Props {
  golfCourse: { [key: string]: string; };
  setIsModalOpen: any;
  plan: {}
}

const GolfCourseRoute: React.FC<Props> = ({ golfCourse, setIsModalOpen, plan}) => {
  const [timePoints, setTimePoints] = useState([]);
  const [goalTime, setGoalTime] = useState(28800);
  const routeLineColors = ['red', 'green', 'orange', 'blue'];

  const {register, watch} = useForm({});

  useEffect(() => {
    const localTimePoints = setLocalTimePoints(golfCourse, 28800);
    setTimePoints((prevTimePoints) => localTimePoints);
  }, []);

  watch((data) => {
    setGoalTime(data['goalTime']);
    const localTimePoints = setLocalTimePoints(golfCourse, data['goalTime']);
    setTimePoints((prevTimePoints) => localTimePoints);
  });

  const setLocalTimePoints = (golfCourse, goalTime) => {
    let localTimePoints = [];
    for(let ride_course_key in golfCourse['ride_course']) {
      const oneTimePoints = {}
      let timePointSecond = goalTime;
      golfCourse['ride_course'][ride_course_key]['pickup'] = golfCourse['ride_course'][ride_course_key]['pickup'].sort((n, m) => (m['id'] - n['id']));
      for(let pickup_key in golfCourse['ride_course'][ride_course_key]['pickup']) {
        timePointSecond = timePointSecond - golfCourse['ride_course'][ride_course_key]['pickup'][pickup_key]['duration'] -300;
        oneTimePoints[golfCourse['ride_course'][ride_course_key]['pickup'][pickup_key]['user_id']] = timePointSecond;
      }
      oneTimePoints[golfCourse['ride_course'][ride_course_key]['user_id']] = timePointSecond - golfCourse['ride_course'][ride_course_key]['duration'] - 300;
      localTimePoints.push(oneTimePoints);
    }

    return localTimePoints;
  }

  const timePointFormatConverter = (seconds) => {
    let minutes = Math.floor(seconds / 60);
    if (minutes < 60) {
      return `00：${('0' + minutes).slice(-2)}`;
    }
    const hours = Math.floor(minutes / 60);
    minutes = Math.floor((seconds - hours * 60 * 60) / 60);
    if (minutes == 0) {
      return `${('0' + hours).slice(-2)}：00`;
    }
    return `${('0' + hours).slice(-2)}：${('0' + minutes).slice(-2)}`;
  };

  const timeFormatConverter = (seconds) => {
    let minutes = Math.floor(seconds / 60);
    if (minutes < 60) {
      return `${minutes}分`;
    }
    const hours = Math.floor(minutes / 60);
    minutes = Math.floor((seconds - hours * 60 * 60) / 60);
    if (minutes == 0) {
      return `${hours}時間`;
    }
    return `${hours}時間${minutes}分`;
  };

  const ClickLinkToGora = (reservePageUrlPC) => {
    /*
    gtag('event', 'click', {
      'event_category': 'GORAの予約ページへの遷移のボタン',
      'event_label': reservePageUrlPC,
      'value': 5
    });
     */
  }

  return (
    <div className="ride-course-modal">
      <div className="title-area">
        <h2>出発時間目安</h2>
        <p>渋滞などの道路状況により大きく変わりますので、<br/>ご注意ください</p>
      </div>
      <img src="/images/large-x-mark.svg" onClick={() => setIsModalOpen(false)} className="x-mark" />
      {Object.keys(golfCourse).length !== 0 && Object.keys(golfCourse['ride_course']).map(id => (
        <div className="content">
          <div className="via-wrapper">
            <div className="via-area">
              <div
                className="time">{timePointFormatConverter(!timePoints.length ? '' : timePoints[id][golfCourse['ride_course'][id]['user_id']])}</div>
              <div className="via">
                <p className="start">{golfCourse['ride_course'][id]['user_nickname']}の車出発</p>
                <p className="location">
                  {`${golfCourse['ride_course'][id]['station_name']}(${golfCourse['ride_course'][id]['prefecture']})`}
                </p>
              </div>
            </div>
            <div className={`${routeLineColors[id]}-line`}>
              <img src={`/images/${routeLineColors[id]}-line.svg`}/>
              <span className="time">{timeFormatConverter(golfCourse['ride_course'][id]['duration'])}</span>
            </div>
          </div>
          {golfCourse['ride_course'][id]['pickup'].sort((n, m) => (n['id'] - m['id'])).map(pickup => (
            <div className="via-wrapper">
              <div className="via-area">
                <div
                  className="time">{timePointFormatConverter(!timePoints.length ? '' : timePoints[id][pickup['user_id']])}</div>
                <div className="via">
                  <p className="pickup">{pickup['user_nickname']}をピックアップ</p>
                  <p className="location">
                    {`${pickup['station_name']}(${pickup['prefecture']})`}
                  </p>
                </div>
              </div>
              <div className={`${routeLineColors[id]}-line`}>
                <img src={`/images/${routeLineColors[id]}-line.svg`}/>
                <span className="time">{timeFormatConverter(pickup['duration'])}</span>
              </div>
            </div>
          ))}
          <div className="destination-area">
            <div className="time">{timePointFormatConverter(goalTime)}</div>
            <div className="destination">
              <p className="goal">ゴルフ場到着</p>
              <p className="location">
                {`${golfCourse['name']}(${golfCourse['prefecture']})`}
              </p>
            </div>
          </div>
        </div>
      ))}
      <div className="goal-time-selector">
        <select {...register('goalTime')}>
          <option value="28800">到着時刻を変更</option>
          {
            (() => {
              const list = [];
              for (let i = 21600; i <= 36000; i = i + 600) {
                list.push(<option value={i}>{moment.unix(i - 32400).format('H:mm')}</option>);
              }
              return list;
            })()
          }
        </select>
      </div>
      {plan != null &&
          <div className="golf-course-reservation">
              <a
                  className="apricot-button"
                  href={plan['reserve_page_url_pc']}
                  target="_blank"
                  onClick={ClickLinkToGora(plan['reserve_page_url_pc'])}
              >
                  ゴルフ場を予約する
              </a>
          </div>
      }
    </div>
  )
}

export default GolfCourseRoute