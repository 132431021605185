import React from 'react'
import { slide as Menu } from "react-burger-menu";

const Header: React.FC = () => {
  return (
    <>
      <Menu right>
        <a id="" className="menu-item" href="/terms">サイト利用規約</a>
        <a id="" className="menu-item" href="/policy">プライバシーポリシー</a>
        <a id="" className="menu-item" href="https://blog.golgime.com/?page_id=504" target="_blank">運営会社</a>
      </Menu>
      <a href="/">
        <h1 className="site-title">ゴルギメ</h1>
      </a>
    </>
  )
}

export default Header
