import React, {useState, useEffect, useRef} from 'react'
import Modal from 'react-modal';
import axios from "axios";
import moment from 'moment';
import 'moment/locale/ja';
import GolfCourseRoute from "./commons/GolfCourceRoute";
import WholeEventEditor from "./commons/WholeEventEditor";
import LinearProgress from '@mui/material/LinearProgress';

interface Props {
  authenticity_token: string;
  hash: string;
  area_names: [];
  area_ids: [string],
  areas: { [key: number]: string; };
  start_times: { [key: number]: string; };
  play_styles: { [key: number]: string; };
  exclusion_conditions: { [key: number]: string; };
  minimum_price: number;
  max_price: number;
  date: string;
  start_time_ids: [];
  play_style_ids: [];
  exclusion_condition_ids: [];
}

const golfCourseRoutecustomStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    backgroundColor: '#444444',
    zIndex: '9999',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    height: '420px',
    transform: 'translate(-50%, -50%)',
    borderRadius: '0',
    padding: '0',
  },
};

const wholeEventEditorcustomStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    backgroundColor: '#444444',
    zIndex: '9999',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: '-30%',
    height: '530px',
    transform: 'translate(-50%, -50%)',
    borderRadius: '0',
    padding: '0',
  },
};

Modal.setAppElement('body');

const GolfCourseIndex: React.FC<Props> = (
  {
    authenticity_token,
    hash,
    area_names,
    area_ids,
    areas,
    start_times,
    play_styles,
    exclusion_conditions,
    minimum_price,
    max_price,
    date,
    start_time_ids,
    play_style_ids,
    exclusion_condition_ids
  }) => {

  const [golfCourses, setGolfCourses] = useState([]);
  const [displayedGolfCourses, setDisplayedGolfCourses] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalGolfCourse, setModalGolfCourse] = useState({})
  const [modalPlan, setModalPlan] = useState({})
  const [showMoreButton, setShowMoreButton] = useState(false);
  const [golfCourseResponseCompleted, setGolfCourseResponseCompleted] = useState(false);
  const [clickedMoreViewPlanButton, setclickedMoreViewPlanButton] = useState([]);
  const [modalType, setModalType] = useState('');
  const stopedRequestAPI = useRef(false);
  const afterClickMoreButton = useRef(false);
  const completedPageCount = useRef(1);
  const totalPageCount = useRef(1);

  useEffect(() => {
    if(!stopedRequestAPI.current) {
      getGolfCourses();
    }
  }, []);

  const getGolfCourses = async () => {
    if(golfCourseResponseCompleted) {
      return;
    }
    for(let i = completedPageCount.current; i <= totalPageCount.current; i++) {
      if(moment(date).isBefore(moment())) {
        alert('プレー日を翌日以降に変えてもう一度、検索しなおしてください。');
        return;
      }
      if(!afterClickMoreButton.current && stopedRequestAPI.current) {
        return;
      }
      axios.defaults.headers.common = {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': authenticity_token
      }
      await axios.get(`/api/v1/golf_courses`, {
        params: {
          e: hash,
          date: date,
          page_count: i,
          minimum_price: minimum_price,
          max_price: max_price,
          area_ids: area_ids,
          play_style_ids: play_style_ids,
          exclusion_condition_ids: exclusion_condition_ids
        }
      }).then(res => {
        if(res.data.status == 'SUCCESS') {
          if(res.data.golf_courses.total_page_count == 0) {
            alert('ご指定の検索条件に該当するゴルフ場は見つかりませんでした。検索条件を変えてもう一度、検索しなおしてください。');
          }
          setGolfCourses((prevGolfCourses) => [...prevGolfCourses, ...res.data.golf_courses.list]);
          completedPageCount.current = i;
          if(totalPageCount.current == 1) {
            setDisplayedGolfCourses(res.data.golf_courses.list);
            totalPageCount.current = res.data.golf_courses.total_page_count;
          }
          if(completedPageCount.current >= 2) {
            setShowMoreButton(true);
            if(!afterClickMoreButton.current) {
              stopedRequestAPI.current = true;
            }
          }
        }
      });
    }
    setGolfCourseResponseCompleted(true)
  };

  const displayAllGolfCourse = () => {
    gtag('event', 'click', {
      'event_category': 'ゴルフ場一覧をもっと見るボタン',
      'event_label': 'more',
      'value': 6
    });
    setDisplayedGolfCourses(golfCourses);
    setShowMoreButton(false);
    if(stopedRequestAPI.current && completedPageCount.current < totalPageCount.current) {
      stopedRequestAPI.current = false;
      afterClickMoreButton.current = true;
      completedPageCount.current ++;
      getGolfCourses();
    }
    if(golfCourseResponseCompleted) {
      setShowMoreButton(false);
    }
  }

  const displayAllPlans = (rakuten_id) => (event: any) => {
    gtag('event', 'click', {
      'event_category': 'ゴルフ場のプランをもっと見るボタン',
      'event_label': rakuten_id,
      'value': 3
    });
    setclickedMoreViewPlanButton((prev) => [...prev, rakuten_id] )
    return false;
  };

  const closePlans = (rakuten_id) => (event: any) => {
    setclickedMoreViewPlanButton((prev) => prev.filter((id) => id != rakuten_id) );

    return false;
  };

  const openModal = (golfCourse, plan) => (event: any) => {
    gtag('event', 'click', {
      'event_category': '出発時間を確認ボタン',
      'event_label': `${golfCourse['name']}, ${plan}`,
      'value': 4
    });
    setModalGolfCourse(golfCourse);
    setModalPlan(plan);
    setIsOpen(true);
    setModalType('golfCourseRoute')
  }

  const redirectGoraGolfCourseByClickName = (url) => (event: any) => {
    gtag('event', 'click', {
      'event_category': 'ゴルフ場名をクリックしてGoraのゴルフ場へ遷移',
      'event_label': url,
      'value': 7
    });
    location.href = url;
  }

   const redirectGoraGolfCourseByClickEvaluation = (url) => (event: any) => {
    gtag('event', 'click', {
      'event_category': '評価をクリックしてGoraのゴルフ場へ遷移',
      'event_label': url,
      'value': 8
    });
    location.href = url;
  }

  const redirectGoraPlan = (url) => (event: any) => {
    gtag('event', 'click', {
      'event_category': 'Goraの予約ページへ遷移',
      'event_label': url,
      'value': 5
    });
    location.href = url;
  }

  const openWholeEventEditor = () => {
    gtag('event', 'click', {
      'event_category': 'ゴルフ場検索条件編集ページのモーダル表示ボタン',
      'event_label': 'modal',
      'value': 2
    });
    setIsOpen(true);
    setModalType('wholeEventEditor')
  }

  const starDisplay = (evaluation) => {
    switch(Math.round(evaluation)) {
      case 0:
        return '☆ ☆ ☆ ☆ ☆';
      case 1:
        return '★ ☆ ☆ ☆ ☆'
      case 2:
        return '★ ★ ☆ ☆ ☆'
      case 3:
        return '★ ★ ★ ☆ ☆'
      case 4:
        return '★ ★ ★ ★ ☆'
      case 5:
        return '★ ★ ★ ★ ★'
      end
    }
  };

  const timeFormatConverter = (seconds) => {
    let minutes = Math.floor(seconds/60);
    if (minutes < 60) {
      return `${minutes}分`;
    }
    const hours = Math.floor(minutes/60);
    minutes = Math.floor((seconds - hours*60*60)/60);
    if (minutes == 0) {
      return `${hours}時間`;
    }
    return `${hours}時間${minutes}分`;
  };

  const commitmentConditions = (start_time_ids, play_style_ids, exclusion_condition_ids) => {
    if(start_time_ids.length != 0 || play_style_ids.length != 0 || exclusion_condition_ids.length != 0) {
      return 'こだわり条件あり';
    } else {
      return 'こだわり条件なし';
    }
  }

  const sortGolfCourses = (e) => {
    const sortedGolfCourses = displayedGolfCourses.sort((a, b) => {
      if(e.target.value == 'price-asc') {
        if(a['cheapest_price'] < b['cheapest_price']) return -1;
        if(a['cheapest_price'] > b['cheapest_price']) return 1;
      } else if(e.target.value == 'price-desc') {
        if(a['most_expensive_price'] > b['most_expensive_price']) return -1;
        if(a['most_expensive_price'] < b['most_expensive_price']) return 1;
      } else if(e.target.value == 'evaluation') {
        if(a['evaluation'] > b['evaluation']) return -1;
        if(a['evaluation'] < b['evaluation']) return 1;
      } else if(e.target.value == 'ride-time') {
        if(a['average_area_to_golf_ride_time'] < b['average_area_to_golf_ride_time']) return -1;
        if(a['average_area_to_golf_ride_time'] > b['average_area_to_golf_ride_time']) return 1;
      }
      return 0;
    });
    setGolfCourses([...sortedGolfCourses]);
  };

  const SetIsModalOpen = (boolean) => {
    setIsOpen(boolean)
  }

  return (
    <>
      <div id="search-conditions-wrapper">
        <div className="contents-wrapper">
          <div className="selected-condition-box-wrapper date">
            <div className="box" onClick={openWholeEventEditor}>{moment(date).format("M月D日(dd)")}</div>
          </div>
          <div className="selected-condition-box-wrapper price">
            <div className="box" onClick={openWholeEventEditor}>{minimum_price}円〜{max_price}円</div>
          </div>
          <div className="selected-condition-box-wrapper area">
            <div className="box" onClick={openWholeEventEditor}>{area_names}</div>
          </div>
          <div className="selected-condition-box-wrapper commitment-conditions">
            <div className="box" onClick={openWholeEventEditor}>{commitmentConditions(start_time_ids, play_style_ids, exclusion_condition_ids)}</div>
          </div>
        </div>
      </div>
      <div id="golf-course-list">
        <div className="sort-price">
          <select onChange={(e) => sortGolfCourses(e) }>
            <option value="ride-time">乗車時間が短い順</option>
            <option value="evaluation">人気順</option>
            <option value="price-asc">料金安い順</option>
            <option value="price-desc">料金高い順</option>
          </select>
        </div>
        <div className="clear"></div>

        {displayedGolfCourses.map(golf_course => (
          <div className="golf-course-wrapper">
            <img src={golf_course['image_url']} alt={golf_course['name']} />
            <div className="info-wrapper">
              <div className="detail-wrapper">
                <p className="prefecture">
                  {golf_course['prefecture']}
                </p>
                <a onClick={golf_course['rakuten_affiliate_link'] ? redirectGoraGolfCourseByClickName(golf_course['rakuten_affiliate_link']) : redirectGoraGolfCourseByClickName(`https://booking.gora.golf.rakuten.co.jp/guide/disp/c_id/${golf_course['rakuten_id']}/`)} href="javascript:void(0)" >
                  <h2>
                    {golf_course['name']}
                  </h2>
                </a>
                <a onClick={golf_course['rakuten_affiliate_link'] ? redirectGoraGolfCourseByClickEvaluation(golf_course['rakuten_affiliate_link']) : redirectGoraGolfCourseByClickEvaluation(`https://booking.gora.golf.rakuten.co.jp/guide/disp/c_id/${golf_course['rakuten_id']}/`)} href="javascript:void(0)">
                  <p className={`evaluation ${golf_course['beginner_symbol'] ? 'beginner-symbol' : ''}`}>
                    {golf_course['evaluation']}&nbsp;&nbsp;{starDisplay(golf_course['evaluation'])}
                  </p>
                </a>
              </div>
              <div className="access-wrapper">
                { golf_course['average_area_to_golf_ride_time'] != 0 &&
                    <div className="ride-time-wrapper">
                        <p className="title">乗車時間の目安</p>
                        <p className="ride-time">
                          {timeFormatConverter(golf_course['average_area_to_golf_ride_time'])}
                        </p>
                    </div>
                }
                <div className="map">
                  <a href={`https://www.google.com/maps/search/?api=1&query=${golf_course['name']}`} target="_blank">
                    <img src="/images/map.png" />
                  </a>
                </div>
              </div>
              <div className="highway-wrapper">
                <p>{golf_course['highway']}/{golf_course['ic_distance']}</p>
              </div>
            </div>
            <div className="plan-wrapper">
              {golf_course['plan_info'].filter(
                (plan, planIndex) => {
                  return planIndex < 2 || clickedMoreViewPlanButton.includes(golf_course['rakuten_id']);
                }).map((plan) => (
                <a onClick={redirectGoraPlan(plan['reserve_page_url_pc'])} href="javascript:void(0)">
                  <div className="plan-row">
                    <div className="plan-name">
                      <p>{plan['plan_name']}</p>
                      <div className="plan-other-wrapper">
                        <div className="plan-info-wrapper">
                          <ul>
                            <li className={plan['lunch'] != 0 ? "lunch icon ok" : "lunch icon" }>ランチ</li>
                            <li className={plan['cart'] != 0 ? "cart icon ok" : "cart icon"}>カート</li>
                            <li className={plan['caddie'] != 0 ? "cady icon ok" : "cady icon"}>キャディ</li>
                            <li className="one-round icon">1.0ラウンド</li>
                            <li className={plan['assu2sum'] != 0 ? "two-sum icon ok" : "two-sum icon"}>2サム保証</li>
                          </ul>
                        </div>
                        <div className="price-wrapper">
                          <p className="basic-price">￥{plan['basic_price'].toLocaleString()}</p>
                          <p className="all-price">総額￥{plan['all_price'].toLocaleString()}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
                ))}
                </div>
            <div className="button-wrapper">
              { golf_course['plan_info_count'] > 2 && !clickedMoreViewPlanButton.includes(golf_course['rakuten_id']) &&
                <button className="detail-button" onClick={ displayAllPlans(golf_course['rakuten_id']) }>
                  もっと見る
                </button>
              }
              { golf_course['plan_info_count'] > 2 && clickedMoreViewPlanButton.includes(golf_course['rakuten_id']) &&
                <button className="detail-button" onClick={ closePlans(golf_course['rakuten_id']) }>
                  閉じる
                </button>
              }
              { golf_course['average_area_to_golf_ride_time'] != 0 &&
                <button className={`ride-time-button ${golf_course['plan_info_count'] <= 2 ? 'wide' : ''}`}
                        onClick={openModal(golf_course, golf_course['plan_info'][0])}>
                  出発時間を確認
                </button>
              }
            </div>
          </div>
        ))}
        { displayedGolfCourses.length == 0 && !golfCourseResponseCompleted && <LinearProgress color="success" /> }
        { displayedGolfCourses.length != 0 && !golfCourseResponseCompleted && !showMoreButton && <p className="load-data">データ取得中...</p> }
        { showMoreButton && <button onClick={displayAllGolfCourse} className="moreButton">もっと見る</button> }
      </div>

      {
          modalType == 'golfCourseRoute' &&
          <Modal
              isOpen={modalIsOpen}
              onRequestClose={() => setIsOpen(false)}
              style={golfCourseRoutecustomStyles}
              contentLabel="Example Modal"
              ariaHideApp={false}
          >
            <GolfCourseRoute golfCourse={modalGolfCourse} setIsModalOpen={SetIsModalOpen} plan={modalPlan}/>
          </Modal>
      }
      {
          modalType == 'wholeEventEditor' &&
          <Modal
              isOpen={modalIsOpen}
              onRequestClose={() => setIsOpen(false)}
              style={wholeEventEditorcustomStyles}
              contentLabel="Example Modal"
              ariaHideApp={false}
          >
            <WholeEventEditor hash={hash} areas={areas} start_times={start_times} play_styles={play_styles}
                              exclusion_conditions={exclusion_conditions} selectedDate={date}
                              selectedPrice={[minimum_price, max_price]} selectedAreaIds={area_ids}
                              selectedStartTimeIds={start_time_ids} selectedPlayStyleIds={play_style_ids}
                              selectedExclusionConditionIds={exclusion_condition_ids}
                              setIsModalOpen={SetIsModalOpen} authenticity_token={authenticity_token} />
          </Modal>
      }
    </>
  );
}

export default GolfCourseIndex
