import React from "react";
import {Controller, FieldErrors, UseFormWatch} from "react-hook-form";
import { Control } from 'react-hook-form';
import { DatePicker } from 'react-nice-dates';
import { ja } from "date-fns/locale";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { isSaturday, isSunday } from 'date-fns'
import 'react-nice-dates/build/style.css'

interface Props {
  control: Control<any>;
  watch: UseFormWatch<any>;
}

const DateInput: React.FC<Props> = (
  {
    control,
    watch,
  }
) => {
  const modifiers = {
    saturday: date => isSaturday(date),
    sunday: date => isSunday(date),
  }

  const modifiersClassNames = {
    saturday: '-saturday',
    sunday: '-sunday',
  }

  return (
    <div className="selector-wrapper">
      <Controller
        name="date"
        control={control}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <DatePicker
            date={watch('date')}
            onDateChange={onChange}
            locale={ja}
            modifiers={modifiers}
            minimumDate={moment().add(1,'days').toDate()}
            modifiersClassNames={modifiersClassNames} format='yyyy年M月d日'
          >
            {({ inputProps, focused }) => (
              <input
                className={'input' + (focused ? ' -focused' : '') + ' box play-date'}
                {...inputProps}
                placeholder='プレー日を入力'
              />
            )}
          </DatePicker>
        )}
      />
      <img src="/images/clock.svg" className="date-icon"/>
    </div>
  );
}

export default DateInput
