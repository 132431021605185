import React, {useState, useEffect} from "react";
import {useForm, SubmitHandler, Controller} from "react-hook-form";
import moment from 'moment';
import 'moment/locale/ja';
import Slider from "@mui/material/Slider";
import DateInput from "./DateInput";
import {DatePicker} from "react-nice-dates";
import {ja} from "date-fns/locale";
import {isSaturday, isSunday} from "date-fns";
import axios from "axios";

interface Props {
  hash: string;
  areas: { [key: number]: string; };
  start_times: { [key: number]: string; };
  play_styles: { [key: number]: string; };
  exclusion_conditions: { [key: number]: string; };
  selectedDate: string;
  selectedPrice: number[];
  selectedAreaIds: [];
  selectedStartTimeIds: [];
  selectedPlayStyleIds: [];
  selectedExclusionConditionIds: [];
  setIsModalOpen: any;
  authenticity_token: string;
}

interface FormInputs {
  area: [number];
  start_time: [number];
  play_style: [number];
  exclusion_condition: [number];
  date: string;
}

moment.locale('ja');

const WholeEventEditor: React.FC<Props> = (
  {
    hash,
    areas,
    start_times,
    play_styles,
    exclusion_conditions,
    selectedDate,
    selectedPrice,
    selectedAreaIds,
    selectedStartTimeIds,
    selectedPlayStyleIds,
    selectedExclusionConditionIds,
    setIsModalOpen,
    authenticity_token,
  }
  ) => {
  const {
    register,
    watch,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormInputs>({
    defaultValues: {
      date: selectedDate,
      area: selectedAreaIds,
      start_time: selectedStartTimeIds,
      play_style: selectedPlayStyleIds,
      exclusion_condition: selectedExclusionConditionIds
    }
  });

  let min = 0;
  let max = 25000;
  const [price, setPrice] = useState<number[]>(selectedPrice);

  const timeFormatConverter = (seconds) => {
    let minutes = Math.floor(seconds/60);
    if (minutes < 60) {
      return `${minutes}分`;
    }
    const hours = Math.floor(minutes/60);
    minutes = Math.floor((seconds - hours*60*60)/60);
    if (minutes == 0) {
      return `${hours}時間`;
    }
    return `${hours}時間${minutes}分`;
  };

  const onSubmit: SubmitHandler<FormInputs> = (data) => {
    axios.defaults.headers.common = {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN' : authenticity_token
    }
    const event = {
      event: {
        area_ids: data.area,
        minimum_price: price[0],
        max_price: price[1],
        start_time_ids: data.start_time,
        play_style_ids: data.play_style,
        exclusion_condition_ids: data.exclusion_condition,
        date: moment(data.date).format("YYYY-MM-DD"),
      },
    }
    axios.patch(`/api/v1/events/${hash}`, event).then(res => {
      if (res.data.status == 'SUCCESS') {
        location.href = `/golf_courses?e=${res.data.data.random_string}`;
      }
    })
  };

  const stopScrollingBackContent = () => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    }
  };

  const modifiers = {
    saturday: date => isSaturday(date),
    sunday: date => isSunday(date),
  }

  const modifiersClassNames = {
    saturday: '-saturday',
    sunday: '-sunday',
  }

  useEffect(stopScrollingBackContent, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="golf-course-conditions-edit-form">
      <div className="form-wrapper whole-event-editor">
        <h2>条件の変更</h2>
        <img src="/images/large-x-mark.svg" onClick={() => setIsModalOpen(false)} className="close"/>
        <div className="form whole-event-editor">
          <p className="title">プレー日</p>
          <div className="selector-wrapper">
            <Controller
              name="date"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <DatePicker
                  date={new Date(watch('date'))}
                  onDateChange={onChange}
                  locale={ja}
                  modifiers={modifiers}
                  minimumDate={moment().add(1,'days').toDate()}
                  modifiersClassNames={modifiersClassNames} format='yyyy年M月d日'
                >
                  {({ inputProps, focused }) => (
                    <input
                      className={'input' + (focused ? ' -focused' : '') + ' box play-date'}
                      {...inputProps}
                      placeholder='プレー日を入力'
                    />
                  )}
                </DatePicker>
              )}
            />
            {errors.date && <p className="error">入力してください</p>}
          </div>
          <p className="title">エリア</p>
          <div className="field area">
            {Object.keys(areas).map(id => (
              <span key={id}>
                <input type="checkbox" value={id} id={`selected_area-${id}`} {...register('area')} />
                <label htmlFor={`selected_area-${id}`}>
                  {areas[id]}
                </label>
              </span>
            ))}
          </div>
          <p className="title">プレー料金</p>
          <div className="field price">
            <div className="slider">
              <div className="text-box-wrapper">
                <input type="number" name="minimum_price" value={price[0]} disabled/>
                &nbsp;&nbsp;〜&nbsp;&nbsp;
                <input type="number" name="max_price" value={price[1]} disabled/>
                <span className="yen">円</span>
              </div>
              <Slider
                getAriaLabel={() => 'Temperature range'}
                value={price}
                onChange={(e: Event, newValue: number | number[]) => {
                  setPrice(newValue as number[]);
                }}
                valueLabelDisplay="auto"
                step={2500}
                min={min}
                max={max}
                size="small"
                sx={{
                  width: 230,
                  color: 'success.main',
                }}
              />
            </div>
          </div>
          <p className="title">スタート時間</p>
          <div className="field start-time">
            {Object.keys(start_times).map(id => (
              <span key={id}>
                <input type="checkbox" value={id} id={`start_time-${id}`} {...register('start_time')} />
                <label htmlFor={`start_time-${id}`}>
                  {start_times[id]}
                </label>
              </span>
            ))}
          </div>
          <p className="title">プレイスタイル</p>
          <div className="field play-style">
            {Object.keys(play_styles).map(id => (
              <span key={id}>
                <input type="checkbox" value={id} id={`play_style-${id}`} {...register('play_style')} />
                <label htmlFor={`play_style-${id}`}>
                  {play_styles[id]}
                </label>
              </span>
            ))}
          </div>
          <p className="title">除外指定</p>
          <div className="field exclusion_condition">
            {Object.keys(exclusion_conditions).map(id => (
              <span key={id}>
                <input type="checkbox" value={id}
                       id={`exclusion_condition-${id}`} {...register('exclusion_condition')} />
                <label htmlFor={`exclusion_condition-${id}`}>
                  {exclusion_conditions[id]}
                </label>
              </span>
            ))}
          </div>
        </div>
        <div className="save-button-wrapper">
          <input type="submit" className="apricot-button" value="決定する"/>
        </div>
      </div>
    </form>
  );
}


export default WholeEventEditor
