import React, { useState } from "react";
import Modal from 'react-modal';
import {UseFormRegister, FieldErrors, UseFormWatch} from "react-hook-form";
import moment from 'moment';
import 'moment/locale/ja';

interface Props {
  watch: UseFormWatch<any>;
  start_times: { [key: number]: string; };
  play_styles: { [key: number]: string; };
  exclusion_conditions: { [key: number]: string; };
  cookies_exclusion_conditions: [number];
  openedDetailedInformationModal: boolean;
}

const SelectedDetailedInformation: React.FC<Props> = (
  {
    watch,
    start_times,
    play_styles,
    exclusion_conditions,
    cookies_exclusion_conditions,
    openedDetailedInformationModal,
  }) => {

  const selectedStartTimes = () => {
    if (Array.isArray(watch('startTimes')) && watch('startTimes').length > 0) {
      return `スタート時間：${watch('startTimes').map(id => start_times[id]).join('/')}`;
    }
    return '';
  }

  const selectedPlayStyle = (): string => {
    let playStyleIds = [];
    let playStylesInPriceModalIds = [];
    if (Array.isArray(watch('playStyles')) && watch('playStyles').length > 0) {
      playStyleIds = watch('playStyles').map(id => play_styles[id]);
    }
    if (Array.isArray(watch('playStylesInPriceModal')) && watch('playStylesInPriceModal').length > 0) {
      playStylesInPriceModalIds = watch('playStylesInPriceModal').map(id => play_styles[id]);
    }
    if (playStyleIds.length != 0 || playStylesInPriceModalIds.length != 0) {
      playStyleIds = playStyleIds.concat(playStylesInPriceModalIds);
      return `プレイスタイル：${playStyleIds.join('/')}`;
    }
    return '';
  }

  const selectedExclusionConditions = (): string => {
    if (
      Array.isArray(watch('exclusionConditions')) && watch('exclusionConditions').length > 0 && firstOpenEventEditor()
    ) {
      return `除外条件：${watch('exclusionConditions').map(id => exclusion_conditions[id]).join('/')}`;
    }
    return '';
  }

  const firstOpenEventEditor = () => {
    return cookies_exclusion_conditions != undefined || openedDetailedInformationModal
  }

  const selectedConditions = (): string => {
    return [selectedStartTimes(), selectedPlayStyle(),selectedExclusionConditions()].filter(Boolean).join(' | ')
  }

  const selectedConditionsCount = (): number => {
    return [selectedStartTimes(), selectedPlayStyle(),selectedExclusionConditions()].filter(Boolean).length;
  }

  return (
    <>
      {
        (() => {
          if (selectedConditionsCount() > 0) {
            return (
              <div className="selected-conditions-wrapper">
                <p>{selectedConditions()}</p>
              </div>
            )
          }
        })()
      }
    </>
  );
}

export default SelectedDetailedInformation
